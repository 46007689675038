

export default {
  name: 'EmptyLayout',
  components: {
    pagesError: () => import('@/components/pagesError.vue')
  },
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      info404: {
        title: 'Halaman yang Mofren cari tidak ditemukan.',
        img: '/mb/img/error404.png',
        desc: 'Silakan kembali ke beranda dan baca artikel terbaru yuk!'
      },
      info505: {
        title: 'Server Modoc sedang mengalami kendala.',
        img: '/mb/img/error500.png',
        desc: 'Kami akan segera menangani hal ini. Silakan coba lagi secara berkala ya Mofren'
      },
      otherError: 'An error occurred',
      note404: '404 Page Not Found | Mobility Doctor - All about mobility! Informasi mobilitas terupdate dalam satu platform',
      noteOther: 'An error occurred | Mobility Doctor - All about mobility! Informasi mobilitas terupdate dalam satu platform'
    }
  },
  head () {
    return {
      title: this.error.statusCode === 404 ? this.note404 : this.noteOther
    }
  }
}
