
import global from "~/mixins/global.js";
export default {
  components: {
    // footerSection: () => import('@modoc-component/src.components.footer/footer.vue'),
    footerSection: () => import("@modoc/modoc-mf.components.footer/footer.vue"),
    mobileNavbar: () =>
      import(
        "@modoc/modoc-mf.components.mobile-navbar-new/mobile-navbar-new.vue"
      ),
    navbar: () => import("@modoc/modoc-mf.components.nav-bar/navbar.vue"),
  },
  mixins: [global],
  data: () => ({
    user_profile: {
      avatar: "",
      username: "",
      id: null,
    },
  }),
  methods: {
    async getUserProfile(id) {
      await this.$store
        .dispatch("auth/getUserProfile", id)
        .then((res) => {
          this.user_profile = res.body;
        })
        .catch((err) => {
          throw new Error(err);
        });
      // this.$router.replace('')
    },
  },
};
